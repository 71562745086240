import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Search from "../Search/Search";
import SideNav from "../SideNav/SideNav";
import "./Navbar.scss";
import NavbarDisplay from "./Navbar.styled";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function Navbar(props) {
  const location = useLocation()
  var w = window.innerWidth;
  const [sideNavStatus, setSideNavStatus] = useState(false);
  const [navStatus, setNavStatus] = useState(false);
  const [className, setclassName] = useState("nav");
  const urlyMobile = window.location.hostname.includes("ymobile");


  useEffect(() => {
    if(urlyMobile)
    {
      setclassName("nav nav_yMobile");
    }
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
     //console.log("scroll");
      setclassName("nav nav_scroll");
    } else {
      setclassName("nav");
    }
  };

  const { history } = props;
  const toggleSidenav = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (!sideNavStatus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    setSideNavStatus((prev) => !prev);
  };
  const goHome = () => {
    if (props.resetHome) {
      props.resetHome();
    }

    // localStorage.getItem('activeLocally').replace()
    const urlksa2 = window.location.hostname.includes("ksa2");
    const urlzain = window.location.hostname.includes("zain-iq2");
    const urlgamezone = window.location.hostname.includes("ao.playit");
    const urlcompetition = window.location.hostname.includes("competition");
    //const urlcompetition = window.location.hostname.includes("local");
    const urlasiacell = window.location.hostname.includes("asiacell"); //asiacell
    const urllibya = window.location.hostname.includes("libya");
    const urlsabafon = window.location.hostname.includes("sabafon");

    
    function getMobileOperatingSystem() {
     //console.log("hello");
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }

    var platform = getMobileOperatingSystem();
   //console.log(platform);

    if (urlksa2 || urlzain || urlgamezone || urlcompetition || urlasiacell || urllibya || urlsabafon) {
      var obj = { main: "online", sub: "", category: "" };
    } else {
      var obj = { main: "android", sub: "", category: "" };
    }

    localStorage.setItem("activeLocally", JSON.stringify(obj));
  };

  const search = () => {
    if(location.pathname.includes('action-games')){
      history.push("/action-games/search");
    } else if (location.pathname.includes('brain-games')) {
      history.push('/brain-games/search')
    } else {
      history.push("/search");
    }
  };

  const urlAfricell = window.location.hostname.includes('africell')

  // console.log(props.history.location.pathname.includes('brain-games'));
  return (
    <NavbarDisplay>
      <SideNav
        open={sideNavStatus}
        toggleSidenav={() => {
          setNavStatus(false);
          toggleSidenav();
        }}
      />
      <nav className={className} style={{ maxWidth: 1200 }}>
        <nav className="burgerMenu" onClick={(e) => toggleSidenav(e)} />

        {urlAfricell ? (
          <div className="row" style={{ width: "100%" }}>
            <div className="logo-container">
              <img src="assets/images/africell/logo-part1.png" />
            </div>

            <div className="logo-container">
              <img src="assets/images/africell/logo-part22.png" />
            </div>
          </div>
        ) : props.history.location.pathname.includes("brain-games") ? (
          <Link
            aria-label="Brain-games"
            to="/brain-games"
            onClick={() => {
              history.push("/brain-games");
              setNavStatus(false);
            }}
          >
            <div
              className={urlyMobile ? "brain-logo yMobileLogo" : "brain-logo"}
            >
              <h2 style={{cursor: 'default'}}>BRAIN-X</h2>
            </div>
          </Link>
        ) : props.history.location.pathname.includes("action-games") ? (
          <Link
            aria-label="action-games"
            to="/action-games"
            onClick={() => {
              history.push("/action-games");
              setNavStatus(false);
            }}
          >
            <div
              className={urlyMobile ? "brain-logo yMobileLogo" : "brain-logo"}
              style={{width: '190px'}}
            >
              <h2 style={{cursor: 'default'}}>YALLA ACTION</h2>
            </div>
          </Link>
        ) : (
          <Link
            aria-label="Home"
            to="/"
            onClick={() => {
              goHome();
              setNavStatus(false);
            }}
          >
            <div className={urlyMobile ? "logo yMobileLogo" : "logo"} />
          </Link>
        )}
        <div className="search" onClick={() => search()} />
      </nav>
      <div className="navHight"></div>
      {/* <Search navStatus={navStatus} setNavStatus={() => setNavStatus()} /> */}
    </NavbarDisplay>
  );
}
export default Navbar;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import Loader from "../../../../components/Loader/Loader";
import envo from "../../../../environments/environmentProd";
import FloatingAlert from "../../../../components/FloatingAlert/FloatingAlert";
import Confirm from "./components/Confirm";
import GameDetails from "./components/GameDetails";
import Play from "./components/Play";
import "./GameDetailsPage.scss";
import GameDetailsPageDisplay from "./GameDetailsPage.styled";
import { checkdouble, setLoginData } from "../../../../helpers/helpers";
import GamesSlide from "../GamesSlide";
import queryString from "query-string";
import getHomeGames from "../../../HomePage/loadingData";
import getHomeGamesSlider from "../../../HomePage/loadingSliderData";
import { client } from "../../../../auth/Client";
import { getPhoneByHeader } from "../../../NewAsiacellSubscribePage/services";
import { setAgencyData } from "../../../../helpers/agencyTracker";
import { useFullScreenHandle } from "react-full-screen";
import { getCategoryGames, getSubTabs } from "../../BrainGames";

if (localStorage.getItem("x") === "1") {
} else {
  localStorage.setItem("x", 1);
  window.location.reload(true);
}

const urlcompetition = window.location.hostname.includes("competition");
const globalurl = window.location.href;
// const urlgamezroid = window.location.href.includes('ramdank')
const urlgamezroid =
  window.location.href.includes("ramdan") &&
  !globalurl.includes("asiacell") &&
  !globalurl.includes("jawwal") &&
  !globalurl.includes("zainiq");
//  const urlgamezroid = window.location.href.includes('local')
const urlasiacell = window.location.hostname.includes("asiacell"); //asiacell
let mainTabsInfo = [];

function getMobileOperatingSystem() {
  //console.log("hello");
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

var platform = getMobileOperatingSystem();
//console.log(platform);
//platform = "iOS";
// if (urlasiacell) {
//   mainTabsInfo = [
//     { slug: "online", name: "play_online" }
//   ];

//   const obj = {
//     main: "online",
//     sub: "",
//     category: ""
//   };
//   localStorage.setItem("activeLocally", JSON.stringify(obj))
// }else{
mainTabsInfo = [
  { slug: "android", name: "the_store" },
  { slug: "online", name: "play_online" },
];


const obj = {
  main: "android",
  sub: "",
  category: "Brain",
};

localStorage.setItem("activeLocally", JSON.stringify(obj));

// }

const urlksa2 = window.location.hostname.includes("ksa2");
const urlksa = window.location.hostname.includes("ksa");

const urlzain = window.location.hostname.includes("zain-iq2");
const urlEgy = window.location.hostname.includes("egy");
const urlgamezone = window.location.hostname.includes("ao.playit");
//const urlgamezoneiq = window.location.hostname.includes("gamezone.playit");
const urlgamezoneiq = window.location.hostname.includes("gamezones.mobi");
const urlgamezoneiq2 = window.location.hostname.includes(
  "zainiq.gamezones.mobi"
);
const urlgamezworld = window.location.hostname.includes("aciq.gamezworld.mobi");

const urlethiogamezone =
  window.location.hostname.includes("ao.playit") &&
  window.location.hostname.includes("ethio");

const globalUrl = window.location.hostname;
const urlmtnng = window.location.hostname.includes("mtnng");

const urlYmn = window.location.hostname.includes("gamesstore");
const urlUnitel = window.location.hostname.includes("ao.playit");
const urlyMobile = window.location.hostname.includes("ymobile");
const urllibya = window.location.hostname.includes("libya");
const urlsabafon = window.location.hostname.includes("sabafon");



if (urlgamezone || urlethiogamezone || urlyMobile || urlsabafon) {

  mainTabsInfo = [
    { slug: "online", name: "play_online" },
    { slug: "android", name: "the_store" },
  ];
  const obj = {
    main: "online",
    sub: "",
    category: "",
  };
  localStorage.setItem("activeLocally", JSON.stringify(obj));
}

if (
  urlksa2 ||
  urlzain ||
  urlgamezoneiq ||
  urlgamezoneiq2 ||
  urlgamezworld ||
  urllibya ||
  urlsabafon
) {
  mainTabsInfo.splice(0, 1);
  const obj = {
    main: "online",
    sub: "",
    category: "",
  };
  localStorage.setItem("activeLocally", JSON.stringify(obj));
}

const curr_search = window.location.search;
const data = queryString.parse(curr_search);

const localLang = window.localStorage.getItem(`i18nextLng`);
// document.body.dir = localLang === "en" ? "ltr" : "rtl";

if (localLang === "en" || localLang === "pt" || localLang === "fr") {
  document.body.dir = "ltr";
}

const getActiveLocally = () => {
  const locally = {main: 'online', sub: '', category: 'Brain'};
  if (urlgamezone || urlcompetition) {
    return locally
      ? locally
      : {
          main: "online",
          sub: "",
          category: "",
        };
  } else {
    return locally
      ? locally
      : {
          main: "android",
          sub: "",
          category: "",
        };
  }
};
const setActiveLocally = (obj) => {
  const str = JSON.stringify(obj);
  localStorage.setItem("activeLocally", str);
};


const apiUrl = envo.apiUrl;

//TODO: Add solid star icon for favourite game
function ActionGameDetailsPage(props) {
  const [confirmState, setConfirmState] = useState(false);
  const [playState, setPlayState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [gameState, setGameState] = useState({});
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const gameID = props.match.params.gameID;
  const userID = props.match.params.userID;
  const [visible, setVisible] = useState(false);
  const [eguVisible, setEguVisible] = useState(true);
  const [instVisible, setInstVisible] = useState(false);
  const initActive = { ...getActiveLocally() };
  const initLoading = { type: "home", status: true };
  const [active, setActive] = useState({ ...initActive });
  const [homeGames, setHomeGames] = useState({});
  const [homeGamesSlider, setHomeGamesSlider] = useState({});

  const [activeSliders, setActiveSliders] = useState([]);
  const [activeExclusiveSliders, setActiveExclusiveSliders] = useState([]);

  const [subTabs, setSubTabs] = useState([]);
  const [subTabGames, setSubTabGames] = useState([]);
  const [loading, setLoading] = useState({ ...initLoading });
  const [categoryGames, setCategoryGames] = useState([]);
  const [showexclusive, setshowexclusive] = useState(false);
  const [showslider, setshowslider] = useState(false);
  const [showDouble, setshowDouble] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handle = useFullScreenHandle();
  const [activeCategoryName, setactiveCategoryName] = useState({
    ar: "",
    en: "",
    fr: "",
    pt: "",
  });

  async function checkDouble() {
    const check = await checkdouble();
    if (check) {
      setshowDouble(true);
    }

    localStorage.setItem("showdouble", check);
  }

  const slowlyUnLoad = () => {
    setTimeout(() => setLoading((prev) => ({ ...prev, status: false })), 200);
  };

  const initHome = async (mainTab, games) => {
    setActiveSliders(games[mainTab]["sliders"]);
    setActiveExclusiveSliders(games[mainTab]["exclusive"]);
    if (activeExclusiveSliders.name != "") {
      setshowexclusive(true);
    }

    ////console.log('ex', activeExclusiveSliders.name)
    const subs = getSubTabs(games[mainTab].categories);
    setSubTabs([...subs]);
    // //console.log("game n", subTabs)
    if (active.sub) {
      const subGames = games[mainTab]["categories"][active.sub];
      setSubTabGames([...subGames]);
    } else if (active.category) {
      const target = games[mainTab]["sliders"].find(
        (i) => i.slug === active.category
      );

      setactiveCategoryName({
        ar: target.name_ar,
        en: target.name,
        fr: target.name_fr,
        pt: target.name_pr,
      });
      const catGames = await getCategoryGames(target.slug, active.main);
      setCategoryGames([...catGames]);
    }

    slowlyUnLoad();
    const url = window.location.href;
    if (localStorage.getItem("Modal") === "1") {
    } else if (url.includes("ethio") && data.camp === "true") {
      setTimeout(localStorage.setItem("Modal", 1), 300);
      setTimeout(() => openModal(), 2000);
    }
    // show ads after 6 secound from int home page
  };
  // model open and close functions

  const openModal = () => {
    setTimeout(() => (setVisible(true), 1000));
  };
  const closeModal = () => {
    setVisible(false);
    setEguVisible(false);
  };

  async function loginmtnng() {
    const transactionId = queryString.parse(window.location.search);
    if (transactionId.trxId) {
      try {
        const url = "http://api.playit.mobi/api/v2/nigeria/loginByTrx";
        const options = {
          transactionId: transactionId.trxId,
        };
        const res = await axios.post(url, options);
        if (res.data) {
          const data = res.data.data;
          if (res.data.status == true) {
            if (data.user_id) {
              setLoginData(
                data.user_id,
                data.token,
                data.user_name,
                data.photo,
                data.operator_id,
                data.user_email,
                data.msisdn,
                data.subscriptionContractId
              );
              //window.location.reload();
              setLoading2(true);
              // checkAuth();
            }
          }
        }
      } catch (err) {
        //console.log(err);
      }
    }
  }

  async function fetchYmn() {
    var linkheader = "./headercheckyemen.php";
    const num = await getPhoneByHeader(linkheader);
    var phone_h = num;
    if (phone_h != false) {
      var userid = localStorage.getItem("userId");
      if (userid == "") {
        loginYmnByHeader(phone_h);
      }
    }

    //loginYmnByHeader(123)

    const url = window.location.search;
    if (url !== "") {
      //console.log("tracker")
      setAgencyData(url);
    }
  }

  async function loginYmnByHeader(phone) {
    const dataOject = {
      MSISDNCG: phone,
    };
    const headers = {
      headers: {
        egauthorization: "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==",
        "Content-Type": "application/json",
      },
    };

    //console.log(headers , dataOject)

    const res = await axios
      .post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
      .then((res) => {
        //console.log(res)
        //console.log(res.data.status)
        return;
        if (res.data.status) {
          var loged = setLoginData(
            res.data.user_id,
            res.data.token,
            res.data.user_name,
            res.data.photo,
            res.data.operator_id,
            res.data.user_email,
            res.data.msisdn
          );
          if (loged) {
            window.location.href = "/";
          } else {
          }
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  async function fetchUnitel() {
    var linkheader = "http://ao.playit.mobi/checkheaderunitel.php";
    const num = await getPhoneByHeader(linkheader);
    var phone_h = num;
    if (phone_h != false) {
      //loginUnitelByHeader(phone_h);
      const auth = await client.auth();
      //console.log(auth);
      if (!auth) {
        loginUnitelByHeader(phone_h);
      }
      // var userid = localStorage.getItem("userId");
      // //console.log(userid);
      // if(userid =="" || userid === null){
      //   loginUnitelByHeader(phone_h);
      // }
    }

    //loginYmnByHeader(123)

    // const url = window.location.search;
    // if (url !== "") {
    //   //console.log("tracker")
    //   setAgencyData(url);
    // }
  }

  async function loginUnitelByHeader(phone) {
    const dataOject = {
      MSISDNCG: phone,
    };
    const headers = {
      headers: {
        egauthorization: "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==",
        "Content-Type": "application/json",
      },
    };

    //console.log(headers , dataOject)

    const res = await axios
      .post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
      .then((res) => {
        //console.log(res)
        //console.log(res.data.status)
        if (res.data.status) {
          var loged = setLoginData(
            res.data.user_id,
            res.data.token,
            res.data.user_name,
            res.data.photo,
            res.data.operator_id,
            res.data.user_email,
            res.data.msisdn
          );
          if (loged) {
            window.location.href = "/";
          } else {
          }
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  useEffect(() => {
    if (urlmtnng) {
      loginmtnng();
    }

    if (urlYmn) {
      fetchYmn();
    }
    if (urlUnitel) {
      fetchUnitel();
    }

    //checkDouble();
    async function init() {
      const res = await getHomeGames();
      const resSlider = await getHomeGamesSlider();

      setHomeGames(res);

      setHomeGamesSlider(resSlider.slide_games);
      initHome(active.main, res);
    }
    init();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoadingState(true);

      if (userID) {
        var paramsuser = {
          UOLID: userID,
        };
        const res = await axios.post(
          "http://www.api.playit.mobi/api/v2/login",
          paramsuser
        );
        //console.log("res" , res.data)
        if (res.data.status === true) {
          if (res.data.user_id) {
            //console.log("task")
            setLoginData(
              res.data.user_id,
              res.data.token,
              res.data.user_name,
              res.data.photo,
              res.data.operator_id,
              res.data.user_email,
              res.data.msisdn
            );
          }
        }
      }

      const options = {
        headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
      };
      const params = {
        id: gameID,
      };
      try {
        const res = await axios.post(`${apiUrl}/playit/games`, params, options);
        if (!res.data) return false;
        const data = res.data;

        if (data.status !== "success") {
          return false;
        }
        setGameState(data.game);
        setLoadingState(false);
      } catch (err) {
        setLoadingState(false);
        setErrorState(true);
        setErrorMsg(props.t("network_failed"));
      }
    }
    fetchData();
  }, [gameID]);
  useEffect(() => {
    if (playState) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [playState]);
  

  return (
    <GameDetailsPageDisplay className={playState && "playing"}>
      {/* <p>test</p> */}
      {confirmState || playState ? (
        <>
          {confirmState && (
            <>
              <Confirm
                setConfirmState={setConfirmState}
                apk={gameState.game_folder}
                name={gameState.game_name}
              />
            </>
          )}
          {playState && (
            <Play gameIframe={gameState.game_folder} gameID={gameID} />
          )}
        </>
      ) : (
        <>
          {loadingState || errorState ? (
            <>
              {loadingState && <Loader type="gamePage" />}
              {errorState && (
                <>
                  <FloatingAlert type="error">
                    {errorMsg}{" "}
                    <a className="reload" href=" ">
                      <Trans>try_again</Trans>
                    </a>
                  </FloatingAlert>
                </>
              )}
            </>
          ) : (
            <>
              <GameDetails
                game={gameState}
                setPlayState={setPlayState}
                setConfirmState={setConfirmState}
                history={props.history}
              />
              {categoryGames.length && <GamesSlide
                name={'Games'}
                category={"Games"}
                games={categoryGames.slice(0, 6)}
              />}
            </>
          )}
        </>
      )}
    </GameDetailsPageDisplay>
  );
}

export default withTranslation()(ActionGameDetailsPage);

import React, { Component } from "react";
import Img from "react-image";
import { Link } from "react-router-dom";
import failedImg from "../../../../assets/trux.png";
import h from "../../../../helpers/helpers";
import "./SingleGame.scss";
import SingleGameDisplay, { GameNameOverlay, ImgContainer } from "./SingleGame.styled";
export default class SingleGame extends Component {
  render() {
    const { img, name, nameAr, gameID } = this.props;
    const gameName = h.getNameByLang(name, nameAr, "");
    return (
      <SingleGameDisplay>
        <Link to={`brain-games/game/${gameID}`}>
          {/* <Thumb img={encodeURI(img)}></Thumb> */}
          <ImgContainer>
            <Img
              className="imgloader"
              src={`${img}`}
              alt={"loader"}
              loader={
                <div className="imgloader ">
                  <div className="loadingPlayit"></div>
                </div>
              }
              unloader={
                <div className="imgloader">
                  <img className=" onFail" src={failedImg} alt="failed" />
                </div>
              }
            />
            <GameNameOverlay>{gameName}</GameNameOverlay>
          </ImgContainer>
          {/* <Title className="gamesName">{gameName}</Title> */}
        </Link>
      </SingleGameDisplay>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Trans, withTranslation } from "react-i18next";
import "./LibyanaMarketingThree.css";
import axios from "axios";
import { setLoginData } from "../../../helpers/helpers";

import img1 from "../../../assets/images/freepik__10-or-more-games-characters-in-gaming-world__94690.png";
import img3 from "../../../assets/images/worrior.png";
import img4 from "../../../assets/images/spinner.037752c6.png";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import queryString from "query-string";
import { setAgencyData ,saveTracker } from "../../../helpers/agencyTracker";


function LibyanaMarketingThree({ t }) {
  const [showLoading, setshowLoading] = useState(false);
  const [activeData, setActiveData] = useState("");
  const [transactionidentify, setTransactionidentify] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorresverify, seterrorresverify] = useState("");

  const [otpid, setotpid] = useState("");

  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem("tracker");
    if (localStorage.getItem(tracker_value) != "1") {
      window.gtag("event", "conversion", {
        send_to: "AW-11495483898/ZWJ2CLDXnfcZEPrTvOkq",
      });
      localStorage.setItem(tracker_value, "1");
    }
    return false;
  }

  let currentURL = window.location.href;
  let BaseURL = "https://api.playit.mobi/api/";
  const isHttps = currentURL.startsWith("https://");
  const params = queryString.parse(window.location.search);

  const Subscribe = async (e) => {
    setActiveData(e.target.phone_number.value);
    const phone_number_val = e.target.phone_number.value;
    e.preventDefault();

    if (phone_number_val) {
      var phon_n = phone_number_val.toString();

      if (currentURL.startsWith("https://")) {
        BaseURL = "https://apissl.playit.mobi/api/";
      }
      let url = BaseURL + `v2/connex-libya/send-otp-login`;

      const options = {
        msisdn: phon_n,
        transaction_identify: transactionidentify,
        flow: "subscription",
      };

      setshowLoading(true);
      await axios
        .post(url, options)
        .then((res) => {
          if (res.data.status === true) {
            setShowverif(true);
          } else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);
            } else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);
            }
          }
          setshowLoading(false);
        })
        .catch(function (error) {
          setErrorMesg(true);
          setShowverif(false);
        })
        .finally(function () {
        });
    }
  };
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem("tracker")) {
      var op_id = "CcoIV1eQFA";
      if (
        GMSISDN.startsWith("21891") ||
        GMSISDN.startsWith("21893") ||
        GMSISDN.startsWith("91") ||
        GMSISDN.startsWith("93")
      ) {
        op_id = "qiL2bqfIL3";
      }

      datag = {
        key: "parameter",
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem("tracker"),
        identifierKey: "id",
        identifierValue: op_id,
        phoneNum: GMSISDN,
      };
    }
    await saveTracker(datag);
  };
  const verify = async (e) => {
    e.preventDefault();
    if (verifyCode && verifyCode.length === 4) {
      var phon_n = activeData;
      setshowLoading(true);
      if (currentURL.startsWith("https://")) {
        BaseURL = "https://apissl.playit.mobi/api/";
      }

      let url = BaseURL + "v2/connex-libya/login-confirm";

      axios
        .get(url + `?msisdn=${phon_n}&pincode=${verifyCode}&plan=w&flow=subscription`)
        .then((res) => {
          if (res.data.status === true) {
            setVerfiErrorMesg(false);
            if (res.data.data.user_id) {
              if (res.data.data.subscription_status) {
                tracker(phon_n);
                gtag_report_conversion();
                window.location.href =`https://libya.playit.mobi/catch-Libyana?msisdn=${res.data.data.username}`;
              } else {
                seterrorresverify(res.data.data.message);
                setVerfiErrorMesg(true);
              }
              
            } else {
              if (res.data.message != null) {
                seterrorresverify(res.data.msg);
                setVerfiErrorMesg(true);
              }
            }
            
          } else {
            if (res.data.msg != null) {
              seterrorresverify(res.data.msg);
              setVerfiErrorMesg(true);
              setTimeout(() => {
                seterrorresverify("");
              setVerfiErrorMesg(false);
              setShowverif(false);
              }, 3000);
            } else {
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);
              setTimeout(() => {
                seterrorresverify("");
              setVerfiErrorMesg(false);
              setShowverif(false);
              }, 3000);
            }
            
          }
          setshowLoading(false);
        })
        .catch(function (error) {
          
        })
        .finally(function () {
        });
    }
  };
  async function headerSignup() {
    if (currentURL.startsWith("https://")) {
      BaseURL = "https://apissl.playit.mobi/api/";
    }
  
    var url =
      BaseURL +
      `v2/connex-libya/get-protected-script?targeted_element=%23cta_button`;
  
      axios
        .get(url)
        .then((res) => {
          if (res.data.status === true) {
            const addScript = document.createElement("script");
            addScript.innerHTML = res.data.dcbprotect;
            document.body.appendChild(addScript); 
            
            setTransactionidentify(res.data.transaction_identify);
            setshowLoading(false);
            
            var event = new Event("DCBProtectRun");
            document.dispatchEvent(event);
            
          } else {
            setErrorMesg(true);
            setErrorMesgSubscribe(res.data.message);
          }
  
          setshowLoading(false);
        })
        .catch(function (error) {
          console.error("An error occurred:", error);
        })
        .finally(function () {
          console.log("Request finished.");
        });
   
  }
  
 

  useEffect(() => {
    if (isHttps) {
      BaseURL = "https://apissl.playit.mobi/api/";
    }
    
    if (!params.lang) {
      localStorage.setItem("i18nextLng", 'ar');
    } else {
      localStorage.setItem("i18nextLng", params.lang);
    }
        const urlsearch = window.location.search;
          if (urlsearch !== "") {
            setAgencyData(urlsearch);
          }
        
              
         
           
              headerSignup();
          
      
                       
    
  }, []);
  return (
    <>
      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}
      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className="LibyanaMarketingThree ">
        <div className="  marketingcontent  ">
          <div className="mb-4  ">
            <Link className="gamezoneLogo" to="/">
              <div className="logo   cptpl_logo" alt="service logo" />
            </Link>
          </div>
          <>
            <div className="container position-relative">
              <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 5 L10 5 L15 0 L94.9 0 L99.9 15 L99.9 100 L85 100 L80 95 L5 95 L0 85 L0 5 Z"
                  vectorEffect="non-scaling-stroke"
                ></path>
              </svg>

              <img className="worrior" src={img3} alt="img1" />
              <img className="toyspinner" src={img4} alt="img1" />

              <div className="sub-details position-relative text-center">
                <img className="coverImg mt-3" src={img1} alt="img1" />
                {!showverif ? (<>
                  <div className=" cptpl_service mt-3">
                  <p className="">
                    <Trans>
                      Playit offers more than 500 games for game lovers
                    </Trans>
                  </p>
                </div>
                <form
                  className="formsubmit px-2"
                  name="formsubmit"
                  onSubmit={(e) => {
                    e.preventDefault();
                    Subscribe(e);
                  }}
                >
                  <div className="formInput d-flex justify-content-center flex-row-reverse">
                    <input
                      type="text"
                      className="input gamezoneInput gamezonekeyph "
                      value="218"
                      disabled={true}
                    />

                    <input
                      type="text"
                      className="input gamezoneInput gamezonenumph text-start"
                      placeholder="XX XXX XX XX"
                      name="phone_number"
                     
                    />
                  </div>

                  <button
                  id="cta_button"
                    className="  cptpl_subscribe snip1562"
                    type="submit"
                  >
                    {t("Continue to subscribe")}
                  </button>
                </form>

                <div className="">
                  <div id="libya-footer" className="mx-5 mt-4">
                    <p id="libya-price" className="cptpl_price">
                      <Trans>libyana-condtion_tandc4</Trans>
                    </p>
                    <p className="cptpl_terms" id="libya-terms">
                      <Trans>libyana-condtion_term</Trans>
                    </p>
                  </div>
                </div>
                </>
               
                 ) : 
                 <div className="verfiySection formsubmit">
                 <p>
                   <Trans>verifycodeJawwalGameszorid</Trans>
                 </p>
                 <p>الرجاء الانتظار حتى وصول رمز التحقق الى هاتفكم</p>
                 <input
                   type="text"
                   className="input  gamezoneInput  "
                   value={verifyCode}
                   maxLength={4}

                   placeholder={t("Enter_verifycode")}
                   onChange={(e) =>
                     setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))
                   }
                 />
                 <br></br>
                 <br></br> 
                 <button
                   className="snip1562"
                   disabled={verifyCode ? "" : "disabled"}
                   onClick={(e) => {
                     verify(e);
                   }}
                 >
                   {t("Verify")}
                 </button>
               </div>}
              </div>
            </div>
          </>

          {showLoading ? (
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(LibyanaMarketingThree);

import React, { useEffect, useRef, useState } from 'react'
// import Sun from '../../assets/icons/sun.svg'
// import Search from '../../assets/icons/search.svg'
import axios from "axios";
import Avatar from "../../assets/icons/profile/lg-anonymous.svg";
import { StyledAuth, StyledAvatar, StyledDropdown, StyledDropdownItem, StyledHeader, StyledIcon, StyledLogo, StyledLogoutBtn, StyledProfile, StyledSearch } from './DesignNavbar.styles'
import Search from '../../assets/icons/Search';
import envo from "../../environments/environmentProd";
import TranslateIcon from '../../assets/icons/TranslateIcon';
import { Link } from "react-router-dom";
import { Trans, getI18n } from 'react-i18next';
// import { client } from "../../auth/Client";
// import { getLoginData } from '../../helpers/helpers';
import AuthContext from '../../ContextApi/AuthContext';
import { useContext } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Light from '../../assets/icons/Light';
const api = envo.apiUrl;

const DesignNavbar = (props) => {
  
    const authContext = useContext(AuthContext);
    const [userInfo, setuserInfo] = useState({
      name: authContext.userProfile.user_name || "",
      email: authContext.user.email || "",
      phone: authContext.userProfile.msisdn || "",
      userPhoto: authContext.user.avatar || "",
      userName: authContext.userProfile.user_name || "",
    });
    const [phoneCancel, setphoneCancel] = useState(
        authContext.userProfile.msisdn && authContext.userProfile.msisdn.startsWith("999")
          ? authContext.userProfile.msisdn.slice(3)
          : authContext.userProfile.msisdn
      );
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();
    const history = useHistory();
    

    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

     const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

     useClickOutside(dropdownRef, () => setIsDropdownOpen(false));
     const [search, setSearch] = useState(true)

     const handleSearch = () => {
      if (
        window.location.pathname.includes("brain-games") &&
        !window.location.pathname.includes("search")
      ) {
        history.push('/brain-games/search')
        setSearch(false)
      } else if (
        window.location.pathname.includes("action-games") &&
        !window.location.pathname.includes("search")
      ) {
        history.push('/action-games/search')
        setSearch(false)
      }

     }

     useEffect(() => {
      window.location.pathname.includes('search') ? setSearch(false) : setSearch(true)
     }, [window.location.pathname])

     const handleDarkMode = () => {
      authContext.isDark ? authContext.setLightMode() : authContext.setDarkMode()
     }

  return (
    <StyledHeader
      aria-label="navbar"
      scrolled={isScrolled}
      isDark={authContext.isDark}
    >
      <StyledLogo>
        <Link
          to={props.isNav === "brain-games" ? "/brain-games" : "/action-games"}
          style={{ color: authContext.isDark ? "white" : "black" }}
        >
          {props.isNav === "brain-games" ? "BRAIN-X" : "YALLA ACTION"}
        </Link>
      </StyledLogo>
      {search && (
        <StyledSearch isDark={authContext.isDark}>
          <input type="text" placeholder="Search" onClick={handleSearch} />
          <Search />
        </StyledSearch>
      )}
      <StyledAuth>
        <StyledIcon isDark={authContext.isDark}>
          <Link
            to={
              props.isNav === "brain-games"
                ? "/brain-games/change-language"
                : "/action-games/change-language"
            }
          >
            <TranslateIcon />
          </Link>
        </StyledIcon>
        <StyledIcon onClick={handleDarkMode} isDark={authContext.isDark}>
          <Light />
        </StyledIcon>
        {authContext.isAuthenticated ? (
          <StyledProfile ref={dropdownRef} onClick={toggleDropdown}>
            {/* <Link to="/brain-games/profile"> */}
            <StyledAvatar>
              <img
                src={
                  authContext.user.avatar !== "null" &&
                  authContext.user.avatar !== ""
                    ? authContext.user.avatar
                    : Avatar
                }
                alt="avatar"
              />
            </StyledAvatar>
            <p>
              {authContext.user.username
                ? authContext.user.username
                : phoneCancel
                ? phoneCancel
                : "N/A"}
            </p>
            {isDropdownOpen && (
              <StyledDropdown
                language={getI18n().language}
                isDark={authContext.isDark}
              >
                <StyledDropdownItem isDark={authContext.isDark}>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/profile"
                        : "/action-games/profile"
                    }
                  >
                    <Trans>side_nav.profile</Trans>
                  </Link>
                </StyledDropdownItem>
                <StyledDropdownItem isDark={authContext.isDark}>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/my-games"
                        : "/action-games/my-games"
                    }
                  >
                    <Trans>my_games</Trans>
                  </Link>
                </StyledDropdownItem>
                <StyledDropdownItem isDark={authContext.isDark}>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/logout"
                        : "/action-games/logout"
                    }
                  >
                    <Trans>side_nav.logout</Trans>
                  </Link>
                </StyledDropdownItem>
                <StyledDropdownItem isDark={authContext.isDark}>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/unsubscribe"
                        : "/action-games/unsubscribe"
                    }
                  >
                    <Trans>unsubscribe</Trans>
                  </Link>
                </StyledDropdownItem>
              </StyledDropdown>
            )}
          </StyledProfile>
        ) : (
          <div>
            <StyledLogoutBtn
              isDark={authContext.isDark}
              to={
                props.isNav === "brain-games"
                  ? "/brain-games/login"
                  : "/action-games/login"
              }
            >
              login
            </StyledLogoutBtn>
          </div>
        )}
      </StyledAuth>
    </StyledHeader>
  );
}

export default DesignNavbar

export const useClickOutside = (ref, onClose) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
};

import styled from "styled-components";
import starStorke from "../../../../assets/icons/favourite-stroke.svg";
import starSolid from "../../../../assets/icons/favourite.svg";

const GameDetailsPageDisplay = styled.div`
  min-height: 91vh;
  .gameIframe {
    width: 100%;
    height: 100vh;
    min-width: 100% !important;
    min-height: 100vh;
    iframe {
      width: 100%;
      height: 100vh;
      min-height: 100vh;
    }
  }
  .confirm {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .downloadQues {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.28;
    }
  }
  .gameHeader {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    justify-content: space-between;
    flex-direction: column;
    max-width: 80% !important;
    .gameStatistics {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      width: 100%;
      .counters{
        display: flex;
        align-items: center;
        gap: 20px;
        .vertical-line {
          height: 45px;
          width: 1px;
          background: #ffffff45;
          display: flex;
          align-items: center;
        }
      .likes,
      .hours,
      .users {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        div {
          font-size: 16px;
          opacity: 0.75;
          padding-bottom: 7px;
        }
      }
      }
    }
  
  }
  .gameHeader,
  .gameBody {
    padding: 25px;
  }
  .gameData {
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    .titleDetails {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      ${"" /* padding: 0 5px 0 18px; */}
      max-width: 250px;
      .title {
        font-size: 30px;
        line-height: 0.97;
        padding-bottom: 5px;
        text-align: left;
        color: var(--gameDetails);
      }
      .details {
        display: block;
        align-items: center;
        font-size: 17px;
        line-height: 1.33;
        color: var(--gameDetailsType);
        white-space: nowrap;
      }
    }
  }
  .gameBody {
    padding: 25px 25px;
    color:var(--gameDetails)!important;
    max-width: 80% !important;
    display: flex;
    gap: 15px;
    flex-direction: column;
    p {
      margin-top: 10px;
    }

    .desc {
      text-align: left;
      line-height: 1.5;
      opacity: 0.75;
      font-size: 14px;
      padding-bottom: 14px;
    }
    .images {
      display: flex;
      padding-bottom: 14px;
    }

    

  .gameFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    .isFav,
    .notFav {
      width: 50px;
      height: 50px;
    }

    .isFav {
      background: url(${starSolid}) center center no-repeat;
    }
    .notFav {
      background: url(${starStorke}) center center no-repeat;
    }
  }
`;
export default GameDetailsPageDisplay;

export const Img = styled.div`
		border: 1px solid #fff;
  		border-radius: 12px;
		width:80px;
		height:80px;
		background: url('${p => p.img}') center center no-repeat;
    background-size: cover;
    }
    @media (min-width:997) {
      width: 302px;
      height: 201px;
    }
`;

export const Fav = styled.div`
  width:50px;
  height:50px;
  background: ${p => {
    if(p.isFav){
      return `url(${starSolid}) center center no-repeat`
    } else {
      return `url(${starStorke}) center center no-repeat`
    }
  }};
`;
import React, { useState, useEffect, useRef } from "react";
import "./GameList.css";
import SingleGameSlide from "../SingleGame/SingleSlideGame";

const GameListSlide = ({ games }) => {
  const [slidesToShow, setSlidesToShow] = useState(4); // Default slides to show
  const [currentIndex, setCurrentIndex] = useState(1); // Start from the second slide for seamless looping
  const [isHovered, setIsHovered] = useState(false); // To control hover effect on autoplay
  const autoplayRef = useRef(null); // To store the interval id for autoplay

  // Update slides based on window size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setSlidesToShow(1); // Show 1 slide on small screens
      } else {
        setSlidesToShow(4); // Show 4 slides on larger screens
      }
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Call handleResize on initial render
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Autoplay logic: change slide every 3 seconds unless hovered
  useEffect(() => {
    if (isHovered) {
      // Clear autoplay when hovered
      clearInterval(autoplayRef.current);
    } else {
      // Set autoplay to change slides every 3 seconds
      autoplayRef.current = setInterval(() => {
        if (currentIndex + slidesToShow >= games.length + 1) {
          setCurrentIndex(1); // Loop back to the first real slide
        } else {
          setCurrentIndex(currentIndex + slidesToShow);
        }
      }, 3000); // Change every 3 seconds
    }

    // Cleanup on component unmount or when hover changes
    return () => clearInterval(autoplayRef.current);
  }, [currentIndex, isHovered, slidesToShow, games.length]);

  const nextSlide = () => {
    if (currentIndex + slidesToShow >= games.length + 1) {
      setCurrentIndex(1); // Loop back to the first real slide
    } else {
      setCurrentIndex(currentIndex + slidesToShow);
    }
  };

  const prevSlide = () => {
    if (currentIndex - slidesToShow < 1) {
      setCurrentIndex(games.length); // Go to last slide if at start
    } else {
      setCurrentIndex(currentIndex - slidesToShow);
    }
  };

  return (
    <div
      className="game-list-slider"
      onMouseEnter={() => setIsHovered(true)} // Stop autoplay on hover
      onMouseLeave={() => setIsHovered(false)} // Resume autoplay when hover ends
    >
      {/* <button className="slider-button prev" onClick={prevSlide}>
        &#10094;
      </button> */}

      <div
        className="slider-wrapper"
        style={{
          display: "flex",
          transform: `translateX(-${(100 / slidesToShow) * currentIndex}%)`,
          transition: "transform 0.5s ease-in-out",
          gap: '10px'
        }}
      >
        {/* Clone the last slide to create seamless looping */}
        <div
          className="slider-item"
          style={{
            flex: `0 0 ${100 / slidesToShow}%`,
            maxWidth: `${100 / slidesToShow}%`,
          }}
        >
          <SingleGameSlide
            img={games[games.length - 1].img || ''}
            name={games[games.length - 1].name || ''}
            nameAr={games[games.length - 1].name_ar}
            gameID={games[games.length - 1].id}
          />
        </div>

        {/* Render all the slides */}
        {games.map((game, index) => (
          <div
            className="slider-item"
            key={index}
            style={{
              flex: `0 0 ${100 / slidesToShow}%`,
              maxWidth: `${100 / slidesToShow}%`,
            }}
          >
            <SingleGameSlide
              img={game.img}
              name={game.name}
              nameAr={game.name_ar}
              gameID={game.id}
            />
          </div>
        ))}

        {/* Clone the first slide to create seamless looping */}
        <div
          className="slider-item"
          style={{
            flex: `0 0 ${100 / slidesToShow}%`,
            maxWidth: `${100 / slidesToShow}%`,
          }}
        >
          <SingleGameSlide
            img={games[0].img}
            name={games[0].name}
            nameAr={games[0].name_ar}
            gameID={games[0].id}
          />
        </div>
      </div>

      {/* <button className="slider-button next" onClick={nextSlide}>
        &#10095;
      </button> */}
    </div>
  );
};

export default GameListSlide;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import env from "../../../../environments/environmentProd";
import FloatingAlert from './../../../../components/FloatingAlert/FloatingAlert';
import "./Search.scss";
import SearchDisplay from "./Search.styled";
import AuthContext from "../../../../ContextApi/AuthContext";
import Search from "../../../../assets/icons/Search";


const api = env.apiUrl;
function BrainSearch(props) {
  const { t } = props;
  const [searchWords, setSearchWords] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const searchIt = async e => {
  const countryName = localStorage.getItem('country_name');
  const operatorName = localStorage.getItem('operator_name');

  const url = window.location.hostname.includes("ksa2");
  const urlzain = window.location.hostname.includes("zain-iq2"); 

  var res = "";

    e && e.preventDefault();
    try {
      if (url || urlzain) {
        res = await axios.get(`${api}/playit/search?key=${searchWords}&country=${countryName}&operator=${operatorName}&online=1`);
      } else {
        res = await axios.get(`${api}/playit/search?key=${searchWords}&country=${countryName}&operator=${operatorName}`);
      }

      const data = res.data;
      if (data.data && data.data.length > 0) {
        setSearchResults([...data.data]);
        setErrorMsg(props.t(""));
       //console.log(data)
      } 
     

    } catch (err) {
      if (err.response && err.response.status === 404) {
        setErrorState(true);
        setErrorMsg(err.response.data.message);
        return
      }
      setErrorState(true);
      setErrorMsg(props.t("write more than 3 letters , Please try again"));

    }
  };
  const authContext = useContext(AuthContext)
  
  return (
    <SearchDisplay isDark={authContext.isDark}>
      <>
        <form onSubmit={(e) => searchIt(e)}>
          <div className="searchInput">
            {/* <div className="searchIcon" onClick={(e) => searchIt(e)}></div> */}
            <Search onClick={(e) => searchIt(e)} />

            <input
              placeholder={t("search")}
              vale={searchWords}
              onKeyUp={(e) => searchIt(e)}
              onChange={(e) => setSearchWords(e.target.value)}
            />
          </div>
          <div className="results">
            {searchResults.map((i, k) => {
              return (
                <div className="searchContainer">
                  <Link to={`/game/${i._id}`}>
                    <div className="sres" key={`search-s-${k}`}>
                      <img src={i.game_thumb} />
                      <p> {i.game_name}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </form>
        {errorState && (
          <FloatingAlert type="error" className="erroeMss">
            {errorMsg}
            {""}
            <a className="reload" href=" "></a>
          </FloatingAlert>
        )}
      </>
    </SearchDisplay>
  );
}

export default withTranslation()(BrainSearch);

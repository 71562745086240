import React, { Component } from "react";
import AuthContext from "./AuthContext";
import DarkmodeContext from './DarkmodeContext'

import axios from "axios";
import envo from "../environments/environmentProd";
import { setLoginData } from "../helpers/helpers";
const apiUrl = envo.apiUrl;
var urlGamezone = window.location.href.includes("ao.playit");
var urlcompetition = window.location.href.includes("competition");
var urlYemen = window.location.href.includes("gamesstore");

class AuthProvider extends Component {
  state = {
    isAuthenticated: localStorage.getItem("email") ? true : false,
    user: {
      email: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).email
        : "",
      avatar: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).avatar
        : "",
      username: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).username
        : "",
      phone: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).phone
        : "",
    },
    userProfile: {
      msisdn: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).msisdn
        : "",
      user_id: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).user_id
        : "",
      user_name: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).user_name
        : "",
      subscribeDate: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).subscribeDate
        : "",
      SubscriptionDate: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).SubscriptionDate
        : "",
      renewalDate: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).renewalDate
        : "",
      PackageCode: localStorage.getItem("userProfile")
        ? JSON.parse(localStorage.getItem("userProfile")).PackageCode
        : "",
    },
    isDark: true
  };

  login = async (username, password) => {
    if (urlGamezone) {
      if (!username.slice(0, 4).includes("244")) {
        username = "244" + username;
      }
    }
    if (urlcompetition) {
      if (!username.slice(0, 4).includes("741")) {
        username = "741" + username;
      }
    }
    const options = { phone: username, password };
    try {
      const res = await axios.post(`${apiUrl}/login`, options);
      if (!res.data) {
        if (urlYemen) {
          return "0";
        } else {
          return false;
        }
      }
      const data = res.data;
      const url = `${apiUrl}/checkAccessToken/${data.token}`;
      const respons = await fetch(url);
      const userProfile = await respons.json();
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
      const userInfo = { ...userProfile };
      const user = {
        username: data.user_name,
        userid: data.user_id,
        avatar: data.photo,
        operatorid: data.operator_id,
        email: data.email,
        phone: data.msisdn,
        subscriptionContract: data.subscriptionContractId,
        PackageCode: data.PackageCode,
      };
      if (data.status !== true && !data.user_id && !data.token) {
        if (urlYemen) {
          return data.message;
        } else {
          return false;
        }
      }
      localStorage.setItem("userData", JSON.stringify(user));
      localStorage.setItem("token", user.token);
      this.setState({
        isAuthenticated: true,
        user,
        userProfile: {
          msisdn: userProfile.msisdn,
          user_id: userProfile.user_id,
          user_name: userProfile.user_name,
          subscribeDate: userProfile.subscribeDate,
          SubscriptionDate: userProfile.SubscriptionDate,
          renewalDate: userProfile.renewalDate,
        },
      });
      return setLoginData(
        data.user_id,
        data.token,
        data.user_name,
        data.photo,
        data.operator_id,
        data.user_email,
        data.msisdn,
        data.subscriptionContractId
      );
    } catch (err) {
      //console.log(err);
      if (urlYemen) {
        return false;
      } else {
        return false;
      }
    }
  };

  logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userProfile");
    localStorage.removeItem('token')
    this.setState({
      isAuthenticated: false,
      user: {
        email: "",
        avatar: "",
        username: "",
        phone: "",
      },
      userProfile: {
        msisdn: "",
        user_id: "",
        user_name: "",
        subscribeDate: "",
        SubscriptionDate: "",
        renewalDate: "",
        PackageCode: "",
      },
    });
  };

  setDarkMode = () => {
    const root = document.querySelector('#root')
    const body = document.body
    root.style.background = "#141E34";
    body.style.background = "#141E34";
    this.setState({
      ...this.state,
      isDark: true
    }) 
  }

  setLightMode = () => {
    const root = document.querySelector("#root");
    const body = document.body
    root.style.background = "#fff";
    body.style.background = "#fff";
    this.setState({
      ...this.state,
      isDark: false,
    }); 
  }

  render() {
    const { isAuthenticated, user, userProfile, isDark } = this.state;
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          user,
          userProfile,
          login: this.login,
          logout: this.logout,
          isDark,
          setDarkMode: this.setDarkMode,
          setLightMode: this.setLightMode
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;

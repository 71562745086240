import React from "react";

// Define the context with default values
const AuthContext = React.createContext({
  isAuthenticated: false,
  user: {
    email: "",
    avatar: "",
    username: "",
    phone: "",
  },
  userProfile: {
    msisdn: "",
    user_id: "",
    user_name: "",
    subscribeDate: "",
    SubscriptionDate: "",
    renewalDate: "",
    PackageCode: ""
  },
  login: (userData) => {},
  logout: () => {},
});

export default AuthContext;

import styled from "styled-components";
import img from "../../assets/icons/md-anonymous.svg";
import ellipse from "../../assets/icons/ellipse.svg";
const SideNavDisplay = styled.div`
  z-index: 5;
  position: fixed;
  transition: 0.4s;
  position: absolute;
  top: 0;
  // left: ${p => (p.isRTL ? "unset" : p.open ? 0 : "-100vw")};
  // right: ${p => (!p.isRTL ? "unset" : p.open ? 0 : "-100vw")};

  left: ${p => (p.open ? 0 : "-100vw")};
  right: ${p => (p.open ? 0 : "-100vw")};
  
  width: 100vw;
  height: 100vh;
  display: flex;
  .side {
    background: var(--darkBgColor);
    height: 120%;
    top: 0;
    width: 320px;
    position: fixed;
    overflow: scroll;
  }
  .close {
    position: absolute;
    background: none;
    border: none;
    color: var(--textColor);
    content: "x";
    font-size: 38px;
    padding-top: 10px;
    cursor: pointer;
    outline: none;
    top: 0;
     right: ${p => (p.isRTL ? "unset" : "20px")};
     left: ${p => (!p.isRTL ? "unset" : "20px")};

    // right: ${(document.body['dir'] ==='rtl' ? "unset" : "20px")};
  }
  .rest {
    background: transparent;
    flex: 1;
    height: 100%;
  }
  .header {
    padding: 30px 20px;
    display: flex;
    justify-content: flex-start;
    background: var(--bgHeaderMenucolor);
    .img {
      .avatar {
        height: 67px;
        width: 67px;
        background: url('${p => (p.profileImg && p.profileImg !== "null" ? p.profileImg : img)}') center
          center no-repeat;
          background-size: cover;
          border-radius: 50%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 10px;
      div {
        font-size: 18px;
      }
      a {
        font-size: 12px;
        color: var(--textColor);
        line-height: 1.21;
        opacity: 0.5;
        margin: 5px;
        border: 1px solid #fff;
        border-radius: 8px;
        padding: 0px 5px;
      }
    }
  }
  .list {
    padding: 20px 0;
    height: calc(100vh - 134px);
    overflow-y: auto;
    .listItem {
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin: 0 40px 18px 40px;
      font-size: 18px;
      font-weight: 200;
      opacity: 0.9;
      line-height: 1.25;
      color: var(--textcolor);
      .count{
        color:var(--primaryColor);
        font-size: 20px;
        line-height: 1.25;
      }
    }
    .listItem:last-child {
      margin-bottom: 60px;
    }
  }
.listcom
  {
    background: linear-gradient(
      45deg
      , rgba(5,29,74,1) 0%, rgba(4,152,250,1) 100%);
  }
  .listItem.notCompleted{
    justify-content: flex-start;
    align-items: center;
    .ellipse{
      background:url(${ellipse}) center center no-repeat;
      width:7px;
      height:7px;
      padding: 0 15px;
    }
}
`;
export default SideNavDisplay;

import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${({ scrolled, isDark }) =>
    scrolled
      ? isDark
        ? "rgba(20, 20, 20, 0.9)" // Dark mode with scroll
        : "rgba(222, 219, 219, 0.75)" // Light mode with scroll
      : isDark
      ? "transparent" // Dark mode without scroll
      : "transparent"}; // Light mode without scroll
  color: ${({ isDark }) => (isDark ? "#fff" : "#000")}; // Adjust text color
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  padding: 10px;
  z-index: 9999;
  margin-bottom: 20px;
`;


export const StyledIcon = styled.div`
  background: ${({ isDark }) => (isDark ? "#303348" : "white")};
  border: ${({ isDark }) => (isDark ? "#fff" : "1px solid rgb(110, 112, 118)")};
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  cursor: pointer;
  svg {
    fill: ${({ isDark }) => (isDark ? "white" : "rgb(110, 112, 118)")};
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    font-size: 30px;
    cursor: pointer;
    margin: 0;
    opacity: 1 !important;
    color: white;
    :hover {
      color: white;
      font-family: unset;
    }
  }
  @media (max-width: 720px) {
    a {
      font-size: 18px;
    }
  }
`;

export const StyledAuth = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const StyledProfile = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    :hover {
      color: white;
      font-family: unset;
    }
  }
  p {
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
`;

export const StyledAvatar = styled.div`
width:38px;
height: 38px;
cursor: pointer;
img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
`

export const StyledSearch = styled.div`
  position: relative;
  background: ${({ isDark }) => (isDark ? "#303348" : "white")};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  width: 240px;
  border: ${({ isDark }) => (isDark ? "none" : "1px solid rgb(110, 112, 118)")};
  input {
    outline: none;
    border: ${({ isDark }) => (isDark ? "none" : "rgb(110, 112, 118)")};
    width: 90%;
    background: ${({ isDark }) => (isDark ? "#303348" : "white")};
    padding: 5px;
    color: #fff;
    border-radius: 10px;
  }
  svg {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    circle, path {
    stroke: ${({ isDark }) => (isDark ? "#fff" : "rgb(110, 112, 118)")};
    }
  }
  @media (max-width: 720px) {
    width: 140px;
    input {
      width: 80%;
    }
  }
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: ${({ language }) => (language === "en" ? "0" : "unset")};
  left: ${({ language }) => (language === "ar" ? "0" : "unset")};
  background: ${({ isDark }) => (isDark ? "#303348" : "white")};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: ${({ isDark }) => (isDark ? "white" : "rgba(0, 0, 0, 0.1)")};
  border-radius: 8px;
  width: 150px;
  z-index: 1000;
`;

export const StyledDropdownItem = styled.div`
  padding: 0.8rem 1.2rem;
  font-size: 14px;
  color: ${({ isDark }) => (isDark ? "white" : "black")};
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${({ isDark }) =>
      isDark ? "rgb(73, 77, 104)" : "rgba(0, 0, 0, 0.1)"};
  }

  a {
    text-decoration: none;
    color: ${({ isDark }) => (isDark ? "white" : "black")};
  }
`;

export const StyledLogoutBtn = styled(Link)`
  outline: unset;
  border: ${({ isDark }) => (isDark ? "#fff" : "1px solid rgb(110, 112, 118)")};
  background: ${({ isDark }) => (isDark ? "#303348" : "white")};

  padding: 7px;
  border-radius: 7px;
  color: ${({ isDark }) => (isDark ? "white" : "#303348")};
  :hover {
    color: ${({ isDark }) => (isDark ? "white" : "#303348")};
  }
`;
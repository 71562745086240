import styled from "styled-components";
const SearchDisplay = styled.div`
  position: relative;

  .searchInput {
    background-color: ${({ isDark }) =>
      isDark ? "var(--lightBgColor)" : "white"};
    padding: 0 25px;
    display: flex;
    align-items: center;
    border: ${({ isDark }) =>
      isDark ? "none" : "1px solid rgb(110, 112, 118)"};
    svg {
      circle,
      path {
        stroke: ${({ isDark }) => (isDark ? "#fff" : "rgb(110, 112, 118)")};
      }
    }
    input {
      background-color: ${({ isDark }) =>
        isDark ? "var(--lightBgColor)" : "white"};
      width: 100%;
      height: 50px;
      outline: none;
      border: none;
      color: var(--textColor);
      padding: 0 15px;
    }
  }
  .erroeMss {
    line-height: 2.25 !important;
    height: none !important;
  }
  .results {
    box-sizing: border-box;
    height: calc(100vh - 112px);
    width: 100%;
    overflow-y: auto;
    background: ${({ isDark }) => (isDark ? "var(--darkBgColor)" : "white")};
    padding: 20px 25px;
    text-align: justify;
    .sres {
      padding: 15px 30px;
      a {
        color: var(--textColor);
      }
    }
  }
`;
export default SearchDisplay;

import axios from "axios";
import envo from "../environments/environmentProd";
import { setLoginData } from "../helpers/helpers";
const apiUrl = envo.apiUrl;
var urlGamezone = window.location.href.includes("ao.playit");
var urlcompetition = window.location.href.includes("competition");
var urlYemen = window.location.href.includes("gamesstore");
//var urlYemen = window.location.hostname.includes("local");

async function login(username, password, authContext) {

    if (urlGamezone) {
        if (!username.slice(0, 4).includes('244')) {
            username = '244' + username;
        }
    }
    if (urlcompetition) {
        if (!username.slice(0, 4).includes('741')) {
            username = '741' + username;
        }
    }
    const options = { phone: username, password };
    try {
        const res = await axios.post(`${apiUrl}/login`, options);
        if (!res.data) {
            if (urlYemen) {
                return "0";
            } else {
                return false;
            }
        }
        const data = res.data;
        if (data.status !== true && !data.user_id && !data.token) {
            if (urlYemen) {
                return data.message;
            } else {
                return false;
            }
        }
        if(authContext) {
            authContext.login({
              username: data.user_name,
              email: data.user_email,
              phone: data.msisdn,
              avatar: data.photo,
            });
        }
        return setLoginData(data.user_id, data.token, data.user_name, data.photo, data.operator_id, data.user_email, data.msisdn, data.subscriptionContractId);
    } catch (err) {
        //console.log(err);
        if (urlYemen) {
            return false;
        } else {
            return false;
        }
    }
}

export default login;